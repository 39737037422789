<template>
  <section>
    <div class="content-header">
      <h2>Works cited</h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">University of Edinburgh. (n.d.). Enhancing students’ career resilience: Research findings. <a href="https://www.amosshe.org.uk/resources/Documents/AMOSSHE-Insight-2016-17-Edinburgh.pdf" target="_blank">https://www.amosshe.org.uk/resources/Documents/AMOSSHE-Insight-2016-17-Edinburgh.pdf</a></li>
        <li class="pb-3">The <em>Curriculum Toolkit: supporting curriculum design and review</em>. (2020b, June 15). The University of Edinburgh. <a href="https://www.ed.ac.uk/employability/staff/curriculum-design-review" target="_blank">www.ed.ac.uk/employability/staff/curriculum-design-review</a></li>
        <li class="pb-3">EAB. (2019). <em>Embedding Resilience into the Career Process</em>. EAB Global, Inc. <a href="https://eab.com/research/student-affairs/case-study/embedding-resilience-into-the-career-process/" target="_blank">https://eab.com/research/student-affairs/case-study/embedding-resilience-into-the-career-process/</a></li>
        <li class="pb-3">Lizziebknight. (2021, February 17). <em>The SDGs for career exploration and purpose – interview with Dr Candy Ho</em>. Career Guidance for Social Justice.<br><a href="https://careerguidancesocialjustice.wordpress.com/2021/02/17/the-sdgs-for-career-exploration-and-purpose-interview-with-dr-candy-ho/ " target="_blank">careerguidancesocialjustice.wordpress.com/2021/02/17/the-sdgs-for-career-exploration-and-purpose-interview-with-dr-candy-ho/ </a> </li>
        <li class="pb-3">How the UN SDGs Can Support Career Education. (2021, April 15). EdCan Network. <br><a href="https://www.edcan.ca/articles/how-the-un-sdgs-can-support-career-education/ " target="_blank">www.edcan.ca/articles/how-the-un-sdgs-can-support-career-education/ </a></li>
        <li class="pb-3"><em>Challenge Cards</em>. (n.d.). SparkPath.<br><a href="https://mysparkpath.com/ " target="_blank">mysparkpath.com/ </a> </li>
        <li class="pb-3">CEWIL Canada. (n.d.). CEWIL Canada.<br><a href="https://cewilcanada.ca/asicommon/controls/shared/formsauthentication/login.aspx?ReturnUrl=%2f" target="_blank">cewilcanada.ca/asicommon/controls/shared/formsauthentication/login.aspx?ReturnUrl=%2f</a> </li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '07',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
